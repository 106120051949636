<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { TENANT_SUBSCRIPTIONL_STATUS } from '~/app/auth/enums/TenantSubscriptionStatus';

    defineProps({
  collapsed: { type: Boolean }
});
    const { tenant } = storeToRefs(useAuthStore());
</script>

<template>
    <div :class="$theme('layout.sidebar_links_container')">
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/company"
            icon="fa-helmet-safety"
            :label="$t('settings.sidebar.company')"
        />
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/profile"
            icon="fa-user"
            :label="$t('settings.sidebar.profile')"
        />
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/users"
            icon="fa-users"
            :label="$t('settings.sidebar.users')"
        />
        <LayoutSidebarSeparator />
        <template v-if="!tenant?.subscriptionPlanConfig.isCombo">
            <LayoutSidebarLink
                v-if="tenant?.subscriptionStatus != TENANT_SUBSCRIPTIONL_STATUS.IN_TRIAL"
                :collapsed="collapsed"
                to="/settings/subscription"
                icon="fa-credit-card"
                :label="$t('settings.sidebar.subscription')"
            />
            <LayoutSidebarLink
                v-else
                :collapsed="collapsed"
                to="/settings/subscription/trial"
                icon="fa-credit-card"
                :label="$t('settings.sidebar.subscription')"
            />
            <LayoutSidebarSeparator />
        </template>
        <!-- <LayoutSidebarLink :collapsed="collapsed" to="/settings/inbox" icon="fa-inbox" :label="$t('settings.sidebar.inbox')" /> -->
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/outbox"
            icon="fa-inbox-out"
            :label="$t('settings.sidebar.outbox')"
        />

        <LayoutSidebarSeparator />
        <LayoutSidebarLink
            :collapsed="collapsed"
            to="/settings/terms-of-service"
            icon="fa-file-contract"
            :label="$t('settings.sidebar.terms-of-service')"
        />
    </div>
</template>
